export const AccordianDefault = () => import('../../components/blocks/accordianDefault.vue' /* webpackChunkName: "components/accordian-default" */).then(c => wrapFunctional(c.default || c))
export const AccordianItemDefault = () => import('../../components/blocks/accordianItemDefault.vue' /* webpackChunkName: "components/accordian-item-default" */).then(c => wrapFunctional(c.default || c))
export const BasiccardlistingDefault = () => import('../../components/blocks/basiccardlistingDefault.vue' /* webpackChunkName: "components/basiccardlisting-default" */).then(c => wrapFunctional(c.default || c))
export const CardsliderDefault = () => import('../../components/blocks/cardsliderDefault.vue' /* webpackChunkName: "components/cardslider-default" */).then(c => wrapFunctional(c.default || c))
export const CookieControl = () => import('../../components/blocks/cookieControl.vue' /* webpackChunkName: "components/cookie-control" */).then(c => wrapFunctional(c.default || c))
export const CtagridDefault = () => import('../../components/blocks/ctagridDefault.vue' /* webpackChunkName: "components/ctagrid-default" */).then(c => wrapFunctional(c.default || c))
export const CtaimageDefault = () => import('../../components/blocks/ctaimageDefault.vue' /* webpackChunkName: "components/ctaimage-default" */).then(c => wrapFunctional(c.default || c))
export const DuoImagesDefault = () => import('../../components/blocks/duoImagesDefault.vue' /* webpackChunkName: "components/duo-images-default" */).then(c => wrapFunctional(c.default || c))
export const ExploreResultsDefault = () => import('../../components/blocks/exploreResultsDefault.vue' /* webpackChunkName: "components/explore-results-default" */).then(c => wrapFunctional(c.default || c))
export const ImagectaDefault = () => import('../../components/blocks/imagectaDefault.vue' /* webpackChunkName: "components/imagecta-default" */).then(c => wrapFunctional(c.default || c))
export const LargectaDefault = () => import('../../components/blocks/largectaDefault.vue' /* webpackChunkName: "components/largecta-default" */).then(c => wrapFunctional(c.default || c))
export const LargetextintroDefault = () => import('../../components/blocks/largetextintroDefault.vue' /* webpackChunkName: "components/largetextintro-default" */).then(c => wrapFunctional(c.default || c))
export const LongformtextDefault = () => import('../../components/blocks/longformtextDefault.vue' /* webpackChunkName: "components/longformtext-default" */).then(c => wrapFunctional(c.default || c))
export const QuoteDefault = () => import('../../components/blocks/quoteDefault.vue' /* webpackChunkName: "components/quote-default" */).then(c => wrapFunctional(c.default || c))
export const RelatedDefault = () => import('../../components/blocks/relatedDefault.vue' /* webpackChunkName: "components/related-default" */).then(c => wrapFunctional(c.default || c))
export const ResourcelistDefault = () => import('../../components/blocks/resourcelistDefault.vue' /* webpackChunkName: "components/resourcelist-default" */).then(c => wrapFunctional(c.default || c))
export const ResultsDefault = () => import('../../components/blocks/resultsDefault.vue' /* webpackChunkName: "components/results-default" */).then(c => wrapFunctional(c.default || c))
export const SidebysidectaDefault = () => import('../../components/blocks/sidebysidectaDefault.vue' /* webpackChunkName: "components/sidebysidecta-default" */).then(c => wrapFunctional(c.default || c))
export const SignupForm = () => import('../../components/blocks/signupForm.vue' /* webpackChunkName: "components/signup-form" */).then(c => wrapFunctional(c.default || c))
export const SlideshowDefault = () => import('../../components/blocks/slideshowDefault.vue' /* webpackChunkName: "components/slideshow-default" */).then(c => wrapFunctional(c.default || c))
export const TextDefault = () => import('../../components/blocks/textDefault.vue' /* webpackChunkName: "components/text-default" */).then(c => wrapFunctional(c.default || c))
export const TextimageDefault = () => import('../../components/blocks/textimageDefault.vue' /* webpackChunkName: "components/textimage-default" */).then(c => wrapFunctional(c.default || c))
export const DescCard = () => import('../../components/cards/descCard.vue' /* webpackChunkName: "components/desc-card" */).then(c => wrapFunctional(c.default || c))
export const LargeCard = () => import('../../components/cards/largeCard.vue' /* webpackChunkName: "components/large-card" */).then(c => wrapFunctional(c.default || c))
export const SmallCard = () => import('../../components/cards/smallCard.vue' /* webpackChunkName: "components/small-card" */).then(c => wrapFunctional(c.default || c))
export const SmallCardAlt = () => import('../../components/cards/smallCardAlt.vue' /* webpackChunkName: "components/small-card-alt" */).then(c => wrapFunctional(c.default || c))
export const Icons = () => import('../../components/icons.vue' /* webpackChunkName: "components/icons" */).then(c => wrapFunctional(c.default || c))
export const Kirbyimage = () => import('../../components/kirbyimage.vue' /* webpackChunkName: "components/kirbyimage" */).then(c => wrapFunctional(c.default || c))
export const Kirbylink = () => import('../../components/kirbylink.vue' /* webpackChunkName: "components/kirbylink" */).then(c => wrapFunctional(c.default || c))
export const HeaderDefault = () => import('../../components/header/headerDefault.vue' /* webpackChunkName: "components/header-default" */).then(c => wrapFunctional(c.default || c))
export const HeroDefault = () => import('../../components/hero/heroDefault.vue' /* webpackChunkName: "components/hero-default" */).then(c => wrapFunctional(c.default || c))
export const NavigationBreadcrumb = () => import('../../components/navigation/breadcrumb.vue' /* webpackChunkName: "components/navigation-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const NavigationDefault = () => import('../../components/navigation/navigationDefault.vue' /* webpackChunkName: "components/navigation-default" */).then(c => wrapFunctional(c.default || c))
export const NavigationFooter = () => import('../../components/navigation/navigationFooter.vue' /* webpackChunkName: "components/navigation-footer" */).then(c => wrapFunctional(c.default || c))
export const NavigationOffscreen = () => import('../../components/navigation/navigationOffscreen.vue' /* webpackChunkName: "components/navigation-offscreen" */).then(c => wrapFunctional(c.default || c))
export const PaginationDefault = () => import('../../components/pagination/paginationDefault.vue' /* webpackChunkName: "components/pagination-default" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
