import { render, staticRenderFns } from "./headerDefault.vue?vue&type=template&id=0b926b48&"
import script from "./headerDefault.vue?vue&type=script&lang=js&"
export * from "./headerDefault.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeroDefault: require('/var/www/osch/frontend/components/hero/heroDefault.vue').default})
